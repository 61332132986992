body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overlay {
  position: fixed;
  display: none;
  /*width: 50%;
        height: 50%;*/
  /*top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        background-color: #212529;
        z-index: 2;
        cursor: pointer;
        overflow: auto;
        transform: translate(-50%, -50%);*/
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  /*color: #1c8e78;*/
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

#loginScreen {
  top: 50%;
  left: 50%;
  /*right: 0;
        bottom: 0;
        height: auto;*/
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 10;
  display: none;
  background: white;
  overflow: auto;
  border: 5px solid #cccccc;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

#loginScreen:target,
#loginScreen:target + #overlay1 {
  display: block;
  opacity: 2;
}
